import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import Heading from "../components/Heading";
import Breadcrumbs from "../components/Breadcrumbs";
import { Seo } from "../base/Seo";
import { PostGallery } from "../components/PostGallery";

import "./single-post.scss";

const post = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <>
      <Layout>
        <Seo
          title={frontmatter.title}
          description={frontmatter.short_description}
        />

        <div className="section postBreadcrumbs">
          <Breadcrumbs frontmatter={frontmatter} />
        </div>

        <div className="section">
          <header className="postHeader">
            {frontmatter.featured_img && (
              <div
                className="authorPortrait"
                style={{
                  backgroundImage: `url(${frontmatter.featured_img[0]})`,
                }}
              ></div>
            )}

            <div className="postInfo">
              <Heading tag="h1">
                <span>{frontmatter.title}</span>
              </Heading>

              {frontmatter.authors && (
                <div className="postAuthors">
                  <Link to="/">{frontmatter.authors}</Link>
                </div>
              )}
            </div>
          </header>
        </div>

        <PostGallery post={frontmatter} />

        <div className="section">
          <div className="innerPost">
            <h3>Overview</h3>
            <div dangerouslySetInnerHTML={{ __html: html }} />

            {frontmatter.external_link && (
              <a href={frontmatter.external_link}>Download</a>
            )}
            {frontmatter.direct_link && (
              <a href={frontmatter.direct_link}>Download</a>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default post;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        publish_date(formatString: "MMM YYYY")
        authors
        short_description
        category
        featured_img
        external_link
        direct_link
      }
    }
  }
`;
